/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class TagTargetEnum.
* @enum {}
* @readonly
*/
export default class TagTargetEnum {
    
        /**
         * value: "DATA"
         * @const
         */
        "DATA" = "DATA";

    
        /**
         * value: "ASSET"
         * @const
         */
        "ASSET" = "ASSET";

    
        /**
         * value: "MODEL_VERSION"
         * @const
         */
        "MODEL_VERSION" = "MODEL_VERSION";

    
        /**
         * value: "MODEL"
         * @const
         */
        "MODEL" = "MODEL";

    
        /**
         * value: "DATASET_VERSION"
         * @const
         */
        "DATASET_VERSION" = "DATASET_VERSION";

    
        /**
         * value: "DATASET"
         * @const
         */
        "DATASET" = "DATASET";

    
        /**
         * value: "DEPLOYMENT"
         * @const
         */
        "DEPLOYMENT" = "DEPLOYMENT";

    
        /**
         * value: "PREDICTED_ASSET"
         * @const
         */
        "PREDICTED_ASSET" = "PREDICTED_ASSET";

    

    /**
    * Returns a <code>TagTargetEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/TagTargetEnum} The enum <code>TagTargetEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

