/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DatasetVersionOut from './DatasetVersionOut';
import PicturedUserOut from './PicturedUserOut';
import TagOut from './TagOut';
import WorkerOut from './WorkerOut';

/**
 * The ExtendedDatasetOut model module.
 * @module model/ExtendedDatasetOut
 * @version 1.0.0
 */
class ExtendedDatasetOut {
    /**
     * Constructs a new <code>ExtendedDatasetOut</code>.
     * @alias module:model/ExtendedDatasetOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param id {String} 
     * @param name {String} 
     * @param description {String} 
     * @param nbVersions {Number} 
     * @param _private {Boolean} 
     * @param lastUpdated {String} 
     * @param tags {Array.<module:model/TagOut>} 
     * @param workers {Array.<module:model/WorkerOut>} 
     * @param versions {Array.<module:model/DatasetVersionOut>} 
     */
    constructor(createdAt, updatedAt, createdBy, id, name, description, nbVersions, _private, lastUpdated, tags, workers, versions) { 
        
        ExtendedDatasetOut.initialize(this, createdAt, updatedAt, createdBy, id, name, description, nbVersions, _private, lastUpdated, tags, workers, versions);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, id, name, description, nbVersions, _private, lastUpdated, tags, workers, versions) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['id'] = id;
        obj['name'] = name;
        obj['description'] = description;
        obj['nb_versions'] = nbVersions;
        obj['private'] = _private;
        obj['last_updated'] = lastUpdated;
        obj['tags'] = tags;
        obj['workers'] = workers;
        obj['versions'] = versions;
    }

    /**
     * Constructs a <code>ExtendedDatasetOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedDatasetOut} obj Optional instance to populate.
     * @return {module:model/ExtendedDatasetOut} The populated <code>ExtendedDatasetOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedDatasetOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('nb_versions')) {
                obj['nb_versions'] = ApiClient.convertToType(data['nb_versions'], 'Number');
            }
            if (data.hasOwnProperty('private')) {
                obj['private'] = ApiClient.convertToType(data['private'], 'Boolean');
            }
            if (data.hasOwnProperty('last_updated')) {
                obj['last_updated'] = ApiClient.convertToType(data['last_updated'], 'String');
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [TagOut]);
            }
            if (data.hasOwnProperty('workers')) {
                obj['workers'] = ApiClient.convertToType(data['workers'], [WorkerOut]);
            }
            if (data.hasOwnProperty('versions')) {
                obj['versions'] = ApiClient.convertToType(data['versions'], [DatasetVersionOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedDatasetOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedDatasetOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedDatasetOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['last_updated'] && !(typeof data['last_updated'] === 'string' || data['last_updated'] instanceof String)) {
            throw new Error("Expected the field `last_updated` to be a primitive type in the JSON string but got " + data['last_updated']);
        }
        if (data['tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tags'])) {
                throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
            }
            // validate the optional field `tags` (array)
            for (const item of data['tags']) {
                TagOut.validateJSON(item);
            };
        }
        if (data['workers']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['workers'])) {
                throw new Error("Expected the field `workers` to be an array in the JSON data but got " + data['workers']);
            }
            // validate the optional field `workers` (array)
            for (const item of data['workers']) {
                WorkerOut.validateJSON(item);
            };
        }
        if (data['versions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['versions'])) {
                throw new Error("Expected the field `versions` to be an array in the JSON data but got " + data['versions']);
            }
            // validate the optional field `versions` (array)
            for (const item of data['versions']) {
                DatasetVersionOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExtendedDatasetOut.RequiredProperties = ["created_at", "updated_at", "created_by", "id", "name", "description", "nb_versions", "private", "last_updated", "tags", "workers", "versions"];

/**
 * @member {Date} created_at
 */
ExtendedDatasetOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedDatasetOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ExtendedDatasetOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedDatasetOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ExtendedDatasetOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ExtendedDatasetOut.prototype['description'] = undefined;

/**
 * @member {Number} nb_versions
 */
ExtendedDatasetOut.prototype['nb_versions'] = undefined;

/**
 * @member {Boolean} private
 */
ExtendedDatasetOut.prototype['private'] = undefined;

/**
 * @member {String} last_updated
 */
ExtendedDatasetOut.prototype['last_updated'] = undefined;

/**
 * @member {Array.<module:model/TagOut>} tags
 */
ExtendedDatasetOut.prototype['tags'] = undefined;

/**
 * @member {Array.<module:model/WorkerOut>} workers
 */
ExtendedDatasetOut.prototype['workers'] = undefined;

/**
 * @member {Array.<module:model/DatasetVersionOut>} versions
 */
ExtendedDatasetOut.prototype['versions'] = undefined;






export default ExtendedDatasetOut;

