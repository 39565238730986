/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ExtendedUserOut from './ExtendedUserOut';
import JobRunStatus from './JobRunStatus';

/**
 * The JobRunOut model module.
 * @module model/JobRunOut
 * @version 1.0.0
 */
class JobRunOut {
    /**
     * Constructs a new <code>JobRunOut</code>.
     * @alias module:model/JobRunOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/ExtendedUserOut} 
     * @param lastUpdated {String} 
     * @param createdAgo {String} 
     * @param startTime {Date} 
     * @param endTime {Date} 
     * @param elapsed {String} 
     * @param id {String} 
     * @param jobId {String} 
     * @param status {module:model/JobRunStatus} 
     * @param progress {Number} 
     * @param loggingObjectName {String} 
     */
    constructor(createdAt, updatedAt, createdBy, lastUpdated, createdAgo, startTime, endTime, elapsed, id, jobId, status, progress, loggingObjectName) { 
        
        JobRunOut.initialize(this, createdAt, updatedAt, createdBy, lastUpdated, createdAgo, startTime, endTime, elapsed, id, jobId, status, progress, loggingObjectName);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, lastUpdated, createdAgo, startTime, endTime, elapsed, id, jobId, status, progress, loggingObjectName) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['last_updated'] = lastUpdated;
        obj['created_ago'] = createdAgo;
        obj['start_time'] = startTime;
        obj['end_time'] = endTime;
        obj['elapsed'] = elapsed;
        obj['id'] = id;
        obj['job_id'] = jobId;
        obj['status'] = status;
        obj['progress'] = progress;
        obj['logging_object_name'] = loggingObjectName;
    }

    /**
     * Constructs a <code>JobRunOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JobRunOut} obj Optional instance to populate.
     * @return {module:model/JobRunOut} The populated <code>JobRunOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JobRunOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ExtendedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('last_updated')) {
                obj['last_updated'] = ApiClient.convertToType(data['last_updated'], 'String');
            }
            if (data.hasOwnProperty('created_ago')) {
                obj['created_ago'] = ApiClient.convertToType(data['created_ago'], 'String');
            }
            if (data.hasOwnProperty('start_time')) {
                obj['start_time'] = ApiClient.convertToType(data['start_time'], 'Date');
            }
            if (data.hasOwnProperty('end_time')) {
                obj['end_time'] = ApiClient.convertToType(data['end_time'], 'Date');
            }
            if (data.hasOwnProperty('elapsed')) {
                obj['elapsed'] = ApiClient.convertToType(data['elapsed'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('job_id')) {
                obj['job_id'] = ApiClient.convertToType(data['job_id'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = JobRunStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('progress')) {
                obj['progress'] = ApiClient.convertToType(data['progress'], 'Number');
            }
            if (data.hasOwnProperty('logging_object_name')) {
                obj['logging_object_name'] = ApiClient.convertToType(data['logging_object_name'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>JobRunOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>JobRunOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of JobRunOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          ExtendedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['last_updated'] && !(typeof data['last_updated'] === 'string' || data['last_updated'] instanceof String)) {
            throw new Error("Expected the field `last_updated` to be a primitive type in the JSON string but got " + data['last_updated']);
        }
        // ensure the json data is a string
        if (data['created_ago'] && !(typeof data['created_ago'] === 'string' || data['created_ago'] instanceof String)) {
            throw new Error("Expected the field `created_ago` to be a primitive type in the JSON string but got " + data['created_ago']);
        }
        // ensure the json data is a string
        if (data['elapsed'] && !(typeof data['elapsed'] === 'string' || data['elapsed'] instanceof String)) {
            throw new Error("Expected the field `elapsed` to be a primitive type in the JSON string but got " + data['elapsed']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['job_id'] && !(typeof data['job_id'] === 'string' || data['job_id'] instanceof String)) {
            throw new Error("Expected the field `job_id` to be a primitive type in the JSON string but got " + data['job_id']);
        }
        // ensure the json data is a string
        if (data['logging_object_name'] && !(typeof data['logging_object_name'] === 'string' || data['logging_object_name'] instanceof String)) {
            throw new Error("Expected the field `logging_object_name` to be a primitive type in the JSON string but got " + data['logging_object_name']);
        }

        return true;
    }


}

JobRunOut.RequiredProperties = ["created_at", "updated_at", "created_by", "last_updated", "created_ago", "start_time", "end_time", "elapsed", "id", "job_id", "status", "progress", "logging_object_name"];

/**
 * @member {Date} created_at
 */
JobRunOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
JobRunOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/ExtendedUserOut} created_by
 */
JobRunOut.prototype['created_by'] = undefined;

/**
 * @member {String} last_updated
 */
JobRunOut.prototype['last_updated'] = undefined;

/**
 * @member {String} created_ago
 */
JobRunOut.prototype['created_ago'] = undefined;

/**
 * @member {Date} start_time
 */
JobRunOut.prototype['start_time'] = undefined;

/**
 * @member {Date} end_time
 */
JobRunOut.prototype['end_time'] = undefined;

/**
 * @member {String} elapsed
 */
JobRunOut.prototype['elapsed'] = undefined;

/**
 * @member {String} id
 */
JobRunOut.prototype['id'] = undefined;

/**
 * @member {String} job_id
 */
JobRunOut.prototype['job_id'] = undefined;

/**
 * @member {module:model/JobRunStatus} status
 */
JobRunOut.prototype['status'] = undefined;

/**
 * @member {Number} progress
 */
JobRunOut.prototype['progress'] = undefined;

/**
 * @member {String} logging_object_name
 */
JobRunOut.prototype['logging_object_name'] = undefined;






export default JobRunOut;

