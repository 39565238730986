/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class JobRunStatus.
* @enum {}
* @readonly
*/
export default class JobRunStatus {
    
        /**
         * value: "PENDING"
         * @const
         */
        "PENDING" = "PENDING";

    
        /**
         * value: "RUNNING"
         * @const
         */
        "RUNNING" = "RUNNING";

    
        /**
         * value: "SUCCEEDED"
         * @const
         */
        "SUCCEEDED" = "SUCCEEDED";

    
        /**
         * value: "FAILED"
         * @const
         */
        "FAILED" = "FAILED";

    
        /**
         * value: "KILLED"
         * @const
         */
        "KILLED" = "KILLED";

    

    /**
    * Returns a <code>JobRunStatus</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/JobRunStatus} The enum <code>JobRunStatus</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

