/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class JobType.
* @enum {}
* @readonly
*/
export default class JobType {
    
        /**
         * value: "IMPORT_BUCKET_OBJECTS"
         * @const
         */
        "IMPORT_BUCKET_OBJECTS" = "IMPORT_BUCKET_OBJECTS";

    
        /**
         * value: "INDEX_DATALAKE"
         * @const
         */
        "INDEX_DATALAKE" = "INDEX_DATALAKE";

    
        /**
         * value: "CREATE_ASSETS"
         * @const
         */
        "CREATE_ASSETS" = "CREATE_ASSETS";

    
        /**
         * value: "IMPORT_ASSETS"
         * @const
         */
        "IMPORT_ASSETS" = "IMPORT_ASSETS";

    
        /**
         * value: "CONVERT_TAGS"
         * @const
         */
        "CONVERT_TAGS" = "CONVERT_TAGS";

    
        /**
         * value: "COMPUTE_EVALUATION_METRICS"
         * @const
         */
        "COMPUTE_EVALUATION_METRICS" = "COMPUTE_EVALUATION_METRICS";

    
        /**
         * value: "DUPLICATE_SAMPLE"
         * @const
         */
        "DUPLICATE_SAMPLE" = "DUPLICATE_SAMPLE";

    
        /**
         * value: "IMPORT_CVAT_ANNOTATIONS"
         * @const
         */
        "IMPORT_CVAT_ANNOTATIONS" = "IMPORT_CVAT_ANNOTATIONS";

    
        /**
         * value: "EXPORT_CVAT_ANNOTATIONS"
         * @const
         */
        "EXPORT_CVAT_ANNOTATIONS" = "EXPORT_CVAT_ANNOTATIONS";

    
        /**
         * value: "COMPUTE_DEPLOYMENT_METRICS"
         * @const
         */
        "COMPUTE_DEPLOYMENT_METRICS" = "COMPUTE_DEPLOYMENT_METRICS";

    
        /**
         * value: "CREATE_ASSIGNMENTS"
         * @const
         */
        "CREATE_ASSIGNMENTS" = "CREATE_ASSIGNMENTS";

    
        /**
         * value: "CREATE_REVIEW_ASSIGNMENTS"
         * @const
         */
        "CREATE_REVIEW_ASSIGNMENTS" = "CREATE_REVIEW_ASSIGNMENTS";

    
        /**
         * value: "PROCESS_DATASET_VERSION"
         * @const
         */
        "PROCESS_DATASET_VERSION" = "PROCESS_DATASET_VERSION";

    
        /**
         * value: "LAUNCH_TRAINING"
         * @const
         */
        "LAUNCH_TRAINING" = "LAUNCH_TRAINING";

    
        /**
         * value: "CONTINUOUS_TRAINING"
         * @const
         */
        "CONTINUOUS_TRAINING" = "CONTINUOUS_TRAINING";

    
        /**
         * value: "LAUNCH_SCAN"
         * @const
         */
        "LAUNCH_SCAN" = "LAUNCH_SCAN";

    

    /**
    * Returns a <code>JobType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/JobType} The enum <code>JobType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

